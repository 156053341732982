import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import { Box } from "@mui/material"
import About from "./About"

const subtitle = `The "Lost On You" poster features a soulful composition with a spotlight on a gracefully illuminated guitar. Strings echo emotional depths, bathed in warm hues. Understated typography intertwines seamlessly, capturing the song's essence. The design creates an intimate visual melody, inviting viewers into the emotive world of the music.
  \n\n Designing anti-war posters using hand grenades as prototypes involves juxtaposing the destructive nature of the symbol with messages of peace. The grenades are transformed into symbols of change, employing bold graphics and stark contrasts. Typography conveys powerful anti-war messages, creating impactful visual narratives that challenge and provoke contemplation.
  \n\n Black American anti-war march posters featuring the Statue of Liberty blend symbols of activism and patriotism. The imagery uses powerful contrasts, employing bold colors and dynamic compositions. Typography delivers impactful messages, invoking unity and justice. The design aims to empower, inspire, and amplify the voices advocating for peace and equality.
  \n\n Ed Sheeran Concert Poster: It is the image of Ed Sheeran, smiling and playing guitar, giving people a friendly and warm feeling.
  \n\n Create a warm atmosphere: The background of the poster is a seaside house and blue sky and white clouds, giving people a warm and comfortable feeling, but the overall picture is still cold-toned, but as soon as Ed appears, he uses music to give the whole picture a ring-like atmosphere. Warm light source, this is to convey Ed Sheeran's musical concept: beautiful and natural, reminding people of the beauty of nature, and expressing a warm emotion. Let the viewer feel the warm, romantic and sweet time under the sea breeze.
  \n\n The dark clouds above the head and dense gray tones represent the dark parts and depressing atmosphere of human emotions. But as Ed Sheeran played, the sun gradually appeared, the shadows on the ground began to move away, and the earth changed from gray in the distance to warm yellow nearby. The gracious Ed Sheeran used his healing music to dispel the uneasiness in people's hearts. This warm and overflowing emotion hit people's hearts directly.         
  `

export default function TitlebarBelowImageList() {
  return (
    <Box>
      <About title="Poster" subtitle={subtitle} className="text-left" />

      <div className="w-full flex justify-center align-center">
        <ImageList sx={{ width: 800, height: 800 }}>
          {itemData.map(item => (
            <ImageListItem key={item.img}>
              <img srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=248&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              {/* <ImageListItemBar title={item.title} subtitle={<span>by: {item.author}</span>} position="below" /> */}
              <ImageListItemBar title={item.title} position="below" className="text-white" />
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </Box>
  )
}

const itemData = [
  {
    img: "../images/PosterDesign/1.jpg",
    title: "Poster for the Song(Lost On You)"
  },
  {
    img: "../images/PosterDesign/3.jpg",
    title: "Poster for the Song"
  },
  {
    img: "../images/PosterDesign/2.png",
    title: "Anti-War Poster"
  },
  {
    img: "../images/PosterDesign/4.jpg",
    title: "Poster of racial discrimination against black Americans"
  },
  {
    img: "../images/PosterDesign/5.jpg",
    title: "Ed Sheeran Concert Poster"
  },

  {
    img: "../images/PosterDesign/6.jpeg",
    title: "Ed Sheeran Concert Poster"
  }
]
