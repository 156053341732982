import { Box, Grid, Typography } from "@mui/material"
import React, { Component } from "react"

const About = props => {
  return (
    <Box className="mb-3 p-3 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ">
      <Typography variant="h3" className="font-extrabold text-white">
        {props.title}
      </Typography>
      {/* <div className={`w-full ${props.className}`}>
        <Typography variant="p" className="text-white">
          {props.className === "text-left" && (
            <>
              {props.subtitle}
              <br />
              <br />
            </>
          )}
          {props.className === "text-left" && (
            <>
              {props.subtitle1}
              <br />
              <br />
            </>
          )}
          {props.className === "text-left" && (
            <>
              {props.subtitle2}
              <br />
              <br />
            </>
          )}
          {props.subtitle3}
          <a className="font-extrabold text-blue-600" href="mailto:wcsh0527@gmail.com?subject=Contact me Through Wu's Studio&body=Hello:">
            {props.email}
          </a>
        </Typography>
      </div> */}
      <div className={`w-full ${props.className}`}>
        <Typography variant="p" className="text-white">
          {props.subtitle.split("\n").map((line, lineIndex) => (
            <div key={lineIndex}>
              {line}
              {lineIndex < props.subtitle.split("\n").length - 1 && <br />}
            </div>
          ))}
          <a className="font-extrabold text-blue-600" href="mailto:wcsh0527@gmail.com?subject=Contact me Through Wu's Studio&body=Hello:">
            {props.email}
          </a>
        </Typography>
      </div>
    </Box>
  )
}

export default About
