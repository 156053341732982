import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import { Box } from "@mui/material"
import About from "./About"

const subtitle = `
  Logo: The design concept for Panda Express, featuring the image of China's national treasure, the panda, holding a bowl and enjoying noodles, intricately communicates a narrative of culinary innovation and cultural fusion. From a designer's perspective, this emblem is a visual testament to Panda Express as an elevated and authentic rendition of foreign-style Chinese cuisine crafted by Chinese culinary expertise.
  \n\n The panda, a symbol of China, conveys a sense of warmth, approachability, and cultural pride. Its contentment while savoring noodles not only personifies the joy of dining but also serves as a metaphor for Panda Express's commitment to delivering a satisfying and genuine Chinese culinary experience.
  \n\n The incorporation of the noodle bowl is strategic, symbolizing a bridge between traditional Chinese flavors and global culinary preferences. The meticulous detailing of the bowl, perhaps adorned with subtle Chinese motifs, signifies attention to authenticity in the culinary offerings. The choice of noodles further underlines the brand's dedication to capturing the essence of Chinese cuisine while presenting it in a universally appealing manner.
  \n\n Color palette plays a crucial role, balancing traditional Chinese hues with contemporary tones. The overall design exudes a sense of modernity, emphasizing that Panda Express is not just Chinese food but an evolved, refined expression of the culinary art form, seamlessly blending tradition with innovation.
  \n\n Typography is chosen with precision, ensuring that it complements the visual elements, reflecting both heritage and modernity. The design encapsulates a harmonious blend, signaling Panda Express as an ambassador of Chinese culinary excellence on a global stage, inviting patrons to indulge in an enhanced, authentic, and delightful dining experience.

  `

export default function LogoPage() {
  return (
    <Box>
      <About title="Logo" subtitle={subtitle} className="text-left" />

      <div className="w-full flex justify-center align-center">
        <ImageList sx={{ width: 500, height: 500 }}>
          {itemData.map(item => (
            <ImageListItem key={item.img}>
              <img srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=248&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              {/* <ImageListItemBar title={item.title} subtitle={<span>by: {item.author}</span>} position="below" /> */}
              {/* <ImageListItemBar title={item.title} position="below" className="text-white" /> */}
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </Box>
  )
}

const itemData = [
  {
    img: "../images/logo/1.jpg"
  },
  {
    img: "../images/logo/2.jpg"
  },
  {
    img: "../images/logo/4.jpg"
  }
]
