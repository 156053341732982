import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import { Box } from "@mui/material"
import About from "./About"

export default function FontPictures() {
  return (
    <Box>
      <About title="Font" subtitle="Font design: create differnt typographic combinations that visually express the underlying principles of a discourse or suggestion by applying the basic principles of graphic design." />

      <div className="w-full flex justify-center align-center">
        <ImageList sx={{ width: 500, height: 500 }}>
          {itemData.map(item => (
            <ImageListItem key={item.img}>
              <img srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=248&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              {/* <ImageListItemBar title={item.title} subtitle={<span>by: {item.author}</span>} position="below" /> */}
              {/* <ImageListItemBar title={item.title} position="below" className="text-white" /> */}
            </ImageListItem>
          ))}
        </ImageList>
      </div>
    </Box>
  )
}

const itemData = [
  {
    img: "../images/FontDesign/1.jpg"
  },
  {
    img: "../images/FontDesign/3.jpg"
  },
  {
    img: "../images/FontDesign/2.png"
  },
  {
    img: "../images/FontDesign/4.jpg"
  }
]
