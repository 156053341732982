import * as React from "react"
import { styled } from "@mui/material/styles"
import Box from "@mui/material/Box"
import Paper from "@mui/material/Paper"
import Grid from "@mui/material/Unstable_Grid2"
import { Container, Skeleton, Typography } from "@mui/material"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary
}))

const introduce = [
  {
    src: "../images/logo/ChenshiWu.jpeg",
    title: "Logo4"
  }
  // {
  //   src: "../images/logo/1.jpg",
  //   title: "Logo1"
  // },
  // {
  //   src: "../images/logo/2.jpg",
  //   title: "Logo2"
  // }
]

const Home = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Box className="py-5 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 ">
        <Typography variant="h3" className="font-extrabold text-white">
          Chenshi Wu's Studio
        </Typography>
      </Box>

      <Grid container spacing={2} columns={16} className="p-4">
        <Grid xs={8} className="flex justify-center items-center">
          <Container>
            <Box sx={{ height: "500" }}>
              <div className="p-2 h-full">
                <Container className="p-2">
                  <Typography variant="h4" className="font-extrabold text-white">
                    Introduction
                  </Typography>
                </Container>
                <Typography variant="p" className="text-white">
                  Hello! welcome to my website. My name is chenshi wu, graduated at ocad university illustration and graphic design major. Welcome to view my portfolio through the website.
                </Typography>
              </div>
            </Box>
          </Container>
        </Grid>
        <Grid xs={8}>
          <Container>
            <Box sx={{ height: "200" }}>
              {/* <img className="rounded-full" src=".././" /> */}
              {introduce.map((item, index) => (
                <Box key={index} sx={{ marginRight: 1.5, mx: 3 }}>
                  {item ? <img className="rounded-full" alt={item.title} src={item.src} /> : <Skeleton variant="rectangular" width={200} height={200} />}
                </Box>
              ))}
            </Box>
          </Container>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Home
