import * as React from "react"
import PropTypes from "prop-types"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import Skeleton from "@mui/material/Skeleton"
import Blogs from "./Blogs"
import About from "./About"

const data = [
  {
    src: "../images/CommemorativeCoinDesign/1.jpg"
  },
  {
    src: "../images/CommemorativeCoinDesign/2.jpg"
  },
  {
    src: "../images/CommemorativeCoinDesign/3.jpg"
  },
  {
    src: "../images/CommemorativeCoinDesign/4.jpg"
  }
]

const subtitle = `  Digital Souvenir: from a designer's perspective, the Digital Souvenir for the 2022 4th Real World Capture The Flag (CTF) event is a fusion of digital artistry and blockchain functionality. The visual aesthetic is carefully crafted to encapsulate the essence of cybersecurity, employing symbolic elements like intricate lock and key motifs and dynamic binary code patterns. Vibrant, event-specific colors are chosen to evoke the energy of the digital realm and align with the CTF's branding. 
\n\n   The integration of blockchain technology not only ensures the security of the virtual currency but also inspires design choices. Each unit becomes a unique, collectible piece, amplified by the limited edition status. Typography and layout considerations prioritize clarity for a seamless user experience, while also allowing for the incorporation of security features seamlessly into the visual design.
\n\n   The design emphasizes interactivity, with graphics and animations that respond to user engagement. The use of smart contracts is subtly visualized, adding a layer of sophistication to the overall aesthetic. Cross-platform adaptability is a priority, with a user interface that seamlessly transitions across web and mobile applications.
\n\n   In essence, the designer envisions the Digital Souvenir not just as a representation of a virtual currency but as a dynamic and visually captivating artifact, harmonizing the excitement of cybersecurity challenges with the sleek, cutting-edge nature of blockchain technology.`

const Media = props => {
  const { loading = false } = props

  return (
    <div>
      <About title="Art Work" subtitle={subtitle} className="text-left" />

      <div className="w-full flex justify-center items-center">
        <Box sx={{ height: 500, overflowY: "scroll" }} className="w-9/12">
          <Grid container wrap="wrap" className="flex justify-center align-center w-full">
            {(loading ? Array.from(new Array(4)) : data).map((item, index) => (
              <Box key={index} sx={{ width: 240, marginRight: 1.5, mx: 3 }}>
                {item ? <img style={{ width: 220, height: 220, margin: 10 }} alt={item.title} src={item.src} /> : <Skeleton variant="rectangular" width={240} height={240} />}

                {/* {item ? (
              <Box sx={{ pr: 2 }}>
                <Typography gutterBottom variant="body2">
                  {item.title}
                </Typography>
              </Box>
            ) : (
              <Box sx={{ pt: 0.5 }}>
                <Skeleton />
                <Skeleton width="60%" />
              </Box>
            )} */}
              </Box>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  )
}

Media.propTypes = {
  loading: PropTypes.bool
}

export default function YoutubeMedia() {
  return (
    <div className="w-full">
      <Box sx={{ overflow: "hidden" }}>
        <Media />
      </Box>
    </div>
  )
}
