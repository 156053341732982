import { useState, useEffect } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom"

import CardList from "./components/card-list/card-list.component"
import SearchBox from "./components/search-box/search-box.component"
import "./App.css"

import Button from "@mui/material/Button"
import NavBar from "./components/NavBar"
import PictureGrid from "./components/PictureGrid"
import YoutubeMedia from "./components/YoutubeMedia"
import { Box, Divider } from "@mui/material"

import Blogs from "./components/Blogs"
import Home from "./components/Home"
import TitlebarBelowImageList from "./components/TitlebarBelowImageList"
import MasonryImageList from "./components/MasonryImageList"
import TitlebarImageList from "./components/CustomImageList"
import CustomImageList from "./components/CustomImageList"
import FontPictures from "./components/FontPictures"
import Contact from "./components/Contact"
import LogoPage from "./components/LogoPage"

const App = () => {
  const [searchField, setSearchField] = useState("")
  const [monsters, setMonsters] = useState([])
  const [filteredMonsters, setFilterMonsters] = useState(monsters)
  const [title, setTitle] = useState("")

  useEffect(() => {
    fetch("https://jsonplaceholder.typicode.com/users")
      .then(response => response.json())
      .then(users => setMonsters(users))
  }, [])

  useEffect(() => {
    const newFilteredMonsters = monsters.filter(monster => {
      return monster.name.toLocaleLowerCase().includes(searchField)
    })

    setFilterMonsters(newFilteredMonsters)
  }, [monsters, searchField])

  const onSearchChange = event => {
    const searchFieldString = event.target.value.toLocaleLowerCase()
    setSearchField(searchFieldString)
  }

  const onTitleChange = event => {
    const searchFieldString = event.target.value.toLocaleLowerCase()
    setTitle(searchFieldString)
  }

  return (
    <div className="App">
      {/* <NavBar />
      <Divider />

      <Box className="w-full flex justify-center">
        <YoutubeMedia />
      </Box> */}

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<NavBar />}>
            <Route index element={<Home />} />
            <Route path="/Artwork" element={<YoutubeMedia />} />
            <Route path="/Projects" element={<TitlebarBelowImageList />} />
            <Route path="/Contact" element={<Contact />} />
            <Route path="/Fonts" element={<FontPictures />} />
            <Route path="/Logo" element={<LogoPage />} />

            <Route path="/DVD" element={<MasonryImageList />} />

            {/* <Route path="*" element={<NoPage />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>

      {/* 
      <SearchBox className="monsters-search-box " onChangeHandler={onSearchChange} placeholder="search monsters" />
      <br />
      <SearchBox className="title-search-box" onChangeHandler={onTitleChange} placeholder="Set TItle monsters" />

      <CardList monsters={filteredMonsters} /> */}
    </div>
  )
}

export default App
