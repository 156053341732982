import * as React from "react"
import Box from "@mui/material/Box"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import Blogs from "./Blogs"
import About from "./About"

const subtitle = `
                Vinyl record and CD: for the Eagles' iconic album "Hotel California," the vinyl record and CD cover design undergo a meticulous transformation in this brand-new edition, blending nostalgia with a contemporary twist. The vinyl record, a symbol of timeless musicality, features a high-quality matte finish, enriching the tactile experience for enthusiasts. The cover showcases a reimagined rendition of the original iconic artwork, with a modernized color palette that retains the essence of the '70s while captivating a new generation of listeners.
                \n\n The CD cover, in tandem with the vinyl, mirrors this fusion of classic and modern design. Its compact format allows for a dynamic interplay of visuals, capturing the allure of the Californian landscape that inspired the album. The band's logo is subtly enhanced with a metallic sheen, providing a touch of sophistication.
                \n\n Typography on both covers is thoughtfully selected, offering a balance between readability and artistic expression. The interior packaging of the vinyl unfolds into a visually immersive experience, featuring unseen photographs from the album's era and exclusive liner notes. The CD packaging maintains this spirit, with a booklet containing lyrics and anecdotes, providing fans with a comprehensive journey through the creation of "Hotel California."
                \n\n In essence, the redesigned vinyl and CD covers for the Eagles' "Hotel California" epitomize a harmonious blend of classic and contemporary design elements, paying homage to the timeless musical masterpiece while inviting a new audience to experience the magic of the album in a fresh, visually captivating way.

                `

export default function MasonryImageList() {
  return (
    <div>
      <About title="DVD Design" subtitle={subtitle} className="text-left" />
      <div className="w-full flex justify-center align-center">
        <Box sx={{ width: 800, height: 500, overflowY: "scroll" }}>
          <ImageList variant="masonry" cols={3} gap={8}>
            {itemData.map(item => (
              <ImageListItem key={item.img}>
                <img srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`} src={`${item.img}?w=248&fit=crop&auto=format`} alt={item.title} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Box>
      </div>
    </div>
  )
}

const itemData = [
  {
    img: "../images/DvdDiscCoverDesign/1.jpg",
    title: "1"
  },
  {
    img: "../images/DvdDiscCoverDesign/2.jpg",
    title: "2"
  },
  {
    img: "../images/DvdDiscCoverDesign/3.jpg",
    title: "3"
  },
  {
    img: "../images/DvdDiscCoverDesign/4.jpg",
    title: "4"
  },
  {
    img: "../images/DvdDiscCoverDesign/5.jpeg",
    title: "5"
  },
  {
    img: "../images/DvdDiscCoverDesign/7.jpeg",
    title: "7"
  },
  {
    img: "../images/DvdDiscCoverDesign/9.jpeg",
    title: "9"
  }
]
