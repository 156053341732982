import * as React from "react"
import ImageList from "@mui/material/ImageList"
import ImageListItem from "@mui/material/ImageListItem"
import ImageListItemBar from "@mui/material/ImageListItemBar"
import IconButton from "@mui/material/IconButton"
import StarBorderIcon from "@mui/icons-material/StarBorder"
import Blogs from "./Blogs"
import { Box } from "@mui/material"
import About from "./About"
import { Link } from "@react-email/components"

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format&dpr=2 2x`
  }
}

export default function Contact() {
  return (
    <div>
      <About title="Contact Me" subtitle="If you have any questions or comments, please contact me via " email="Email" />
      <Box className="w-full flex justify-center">
        <ImageList
          sx={{
            width: 500,
            height: 500,
            // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
            transform: "translateZ(0)"
          }}
          rowHeight={200}
          gap={1}
        >
          {itemData.map(item => {
            const cols = item.featured ? 2 : 1
            const rows = item.featured ? 2 : 1

            return (
              <ImageListItem key={item.img} cols={cols} rows={rows}>
                <img {...srcset(item.img, 250, 250, rows, cols)} alt={item.title} loading="lazy" />
                <ImageListItemBar
                  sx={{
                    background: "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " + "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)"
                  }}
                  title={item.title}
                  position="top"
                  actionIcon={
                    <IconButton sx={{ color: "white" }} aria-label={`star ${item.title}`}>
                      <StarBorderIcon />
                    </IconButton>
                  }
                  actionPosition="left"
                />
              </ImageListItem>
            )
          })}
        </ImageList>
      </Box>
    </div>
  )
}

const itemData = [
  // {
  //   img: "https://images.unsplash.com/photo-1551963831-b3b1ca40c98e",
  //   title: "Breakfast",
  //   author: "@bkristastucchio",
  //   featured: true
  // },
  {
    img: "../images/logo/logo-Wu.jpeg",
    //title: "Contact me if you are interested in those.",
    author: "@bkristastucchio",
    featured: true
  }
]
